

export const fullNameRegex = /^[0-9A-Za-z\s]+$/;

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export function validateFullName(inputValue) {
  if (inputValue.trim() === "") {
    return "Name cannot be empty";
  }
  else if (/^\d/.test(inputValue)) 
  {
    return "Name cannot start with a number";
  } 
  else if (!fullNameRegex.test(inputValue)) {
    return "Name should contain only letters, spaces, and numbers";
  }
  return "";
}

export function validateEmail(inputValue) {
  if (!emailRegex.test(inputValue)) {
    return "Please enter a valid email";
  }
  return "";
}

export function validateMobileNumber(mobileNumber) {
  const mobile = mobileNumber.replace(/[^0-9]/g, "");
  if (mobile.trim().length !== 10) {
    return "Mobile number must have 10 digits";
  }
  else if(mobile.charAt(0) === '0'){
    return "Mobile number cannot start with 0";
  }
  return "";
}

export function validatePincode(inputValue, projectName, listPincodes) {
  const pc = inputValue.replace(/\D/g, "");

  if(inputValue===""){
    return "Pincode cannot be empty";
  }
  if (inputValue.length !== 6) {
    return "Pincode must be 6 digits";
  }

  if (projectName === "India Infoline" && !listPincodes.includes(parseInt(pc))) {
      return "Pincode not found";
  }
  return "";
}

export function validateCategory(inputValue) {
  if (inputValue === ""){
    return "Please select a category"
  }
  else{
    return "";
  }
}

const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export function validatePanNumber(inputValue) {
  if(inputValue.length !== 10){
    return "Please enter valid pan";
  }
  else if(!panRegex.test(inputValue)){
    return 'Please enter valid pan';
  }
  else{

  }
}


export const validateFullNameInput  = (name) => {
  if (/^\d/.test(name)) {
    return "Name cannot start with a number";
  } else {
    return "";
  }
};

export const validateEmailInput = (email) => {
  return email.trim() === "" ? "" : "";
};

export const validateMobileNumberInput  = (mobile) => {
  return mobile.replace(/[^0-9]/g, "");
};

export const validatePincodeInput  = (pc, projectName, listPincodes) => {
  pc = pc.replace(/\D/g, "");

  if (projectName === "India Infoline") {
    if (pc.length === 6 && !listPincodes.includes(parseInt(pc))) {
      return "Pincode not found";
    }
  }
  return "";
};
