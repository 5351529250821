import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PayUSuccess from './Components/PayUSuccess';
import PayUFailure from './Components/PayUFailure';
import Share from './Components/Share';
// import AllAvailableCC from './Components/AllAvailableCC';
import LoanCategory from './Components/LoansCategory';
import Website from './Components/Website';
import AllProducts from './Components/AllProducts';
import Form02 from './Components/Form02';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  
  <Router>
    <Routes>
      <Route path="/" element={ <App /> } />
      <Route path="payusuccess" element= { <PayUSuccess/> } />
      <Route path="payufailure" element={ <PayUFailure/> } />
      <Route path="share" element={ <Share/> } />
      <Route path="finAdvisor" element={<Website/>} />
      <Route path='AllProducts' element={<AllProducts/>}/>
      <Route path='AllProducts/Loans' element={<LoanCategory/>}/>
      <Route path="/mf" element= { <Form02/>} />
      {/* <Route path="AllAvailableCC" element={<AllAvailableCC/>} /> */}
    </Routes>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
